/* src/App.css */

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #2d2d2d; /* NVIDIA Dark Gray */
  color: #b8b8b8;
}

#three-js-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

button {
  cursor: pointer;
}
