/* src/components/Hero.css */

.hero {
    position: relative; /* Make the hero section relative for layering */
    height: 100vh;
    width: 100vw;
    background-color: #2d2d2d; /* NVIDIA Dark Gray */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #b8b8b8; /* NVIDIA Light Gray */
    text-align: center;
    overflow: hidden; /* Make sure the canvas doesn't overflow */
  }
  
  .hero h1 {
    font-size: 4rem;
    margin-bottom: 20px;
  }
  
  .accent {
    color: #7eb700; /* NVIDIA Green Accent */
  }
  
  .typewriter {
    display: inline-block;
    margin-left: 5px; /* Space between the word "here" and the effect */
  }
  
  .hero p {
    font-size: 1.5rem;
    margin-bottom: 40px;
  }
  
  .cta-button {
    background-color: #7eb700;
    color: #2d2d2d;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background-color: #b8b8b8;
    color: #1f1f1f;
  }
  
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Canvas is behind everything */
  }
  