/* src/components/Navbar.css */

.navbar {
    width: 100%;
    background-color: #1f1f1f; /* NVIDIA Darker Gray */
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center all items vertically */
    height: 70px; /* Fixed height */
    padding: 0 50px;
    position: fixed;
    top: 0;
    z-index: 1000;
  }
  
  .nav-left {
    display: flex;
    align-items: center; /* Center the logo and nav links vertically */
  }
  
  .logo {
    font-size: 1.5rem;
    color: #7eb700; /* NVIDIA Green Accent */
    font-weight: bold;
    margin-right: 30px; /* Space between logo and links */
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 20px; /* Space between each link */
    margin: 0; /* Remove default margin from the list */
    padding: 0; /* Remove padding to avoid shifting */
    align-items: center; /* Ensure links are centered vertically */
    height: 100%; /* Ensures nav links take full height of navbar */
  }
  
  .nav-links li {
    display: flex;
    align-items: center; /* Vertically center each list item */
  }
  
  .nav-links a {
    color: #7eb700; /* NVIDIA Green Accent */
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
    display: inline-block;
    height: 100%; /* Ensures full height for link items */
    padding: 0 10px; /* Add some padding for better spacing */
    display: flex;
    align-items: center; /* Vertically center text inside the links */
  }
  
  .nav-links a:hover {
    color: #b8b8b8; /* Light gray on hover */
  }
  
  .social-icons {
    display: flex;
    gap: 15px;
    align-items: center; /* Center icons vertically */
  }
  
  .social-icons a {
    color: #7eb700; /* NVIDIA Green Accent for icons */
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #b8b8b8; /* Light gray on hover */
  }
  